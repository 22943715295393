.auth-form-signup {
    list-style: none;
    margin-top: 5%;
    margin-bottom: 5%;
    padding-left: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 4rem;
    width: 100%;
    min-width: 40em;
    min-height: 45em;
    box-shadow: 0 2px 8px rgba(0, 0, 0);
    border-radius: 10px;
    background: rgba(240, 255, 240, 0.8);
}

.auth-form textarea
{
    color: black
}