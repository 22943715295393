.users-list {
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 50rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.UserContainer-background{
  background-image: url('../../assets/white_back_e.jpg');
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh ;
  background-size:cover;
  padding-top: 5px;
}
.UserContainer{
  margin-left: 5em;
  margin-right: 5em;
}