.topic{
    color: black;
    text-align: center;
    font-weight: bolder;
    font-size: xx-large;
    font-family: Arial, Helvetica, sans-serif;
    font-style: italic;
}

hr.col{
    border: solid;
    color: #4CAF50;
}

