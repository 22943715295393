.supplier-list {
    list-style: none;
    padding: 0;
    width: 90%;
    max-width: 50rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.SupplierContainer-background{
    background-image: url('../../assets/white_back_e.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    padding-top: 5px;
    min-height: 115rem;
}
.SupplierContainer{
    margin-left: 5em;
    margin-right: 5em;
}