.errorMod {
    margin: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 6px;
    padding: 1rem;
    overflow: hidden;
    background: white;
    align-content: center;
    text-align: center;
    width: 40%;
    
  }