.supplier-form {
    list-style: none;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 4.5rem;
    width: 100%;
    min-width: 40em;
    min-height: 45em;
    box-shadow: 0 2px 8px rgba(0, 0, 0);
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
}

.supplier-form input,
.supplier-form textarea,
.supplier-form select,
.supplier-form option,
.supplier-form label{
    color: black;
}

.supplier-form input,
.supplier-form textarea,
.supplier-form option {
    margin-bottom: 4em;
}

.supplier-form select{
    margin-bottom: 1em;
    border-bottom: 1px solid black;
}

