.user-profile-item__content {
    width: 25em;
    padding: 0;
}

.user-profile-item__info {
    padding: 1rem;
    text-align: center;
}

.user-profile-item__image {
    width: 100%;
    height: 12.5rem;
    margin-right: auto;
    margin-left: auto;
}

.user-profile-item__image img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.user-profile-item__info h1{
    font-family: 'Gill Sans MT';
}
.user-profile-item__info h2{
    text-align: start;
    font-family: 'Gill Sans MT';
}

.user-profile-item__info h3{
    text-align: start;
    font-family: 'Gill Sans MT';
}

.user-profile-item__actions {
    padding: 0;
    text-align:center;
}

.user-profile-item__modal-content {
    padding: 0;
}

.user-profile-item__modal-actions {
    text-align: right;
}

.user-profile-item__actions button,
.user-profile-item__actions a {
    margin: 0.5rem;
}

@media (min-width: 768px) {
    .user-profile-item__image {
        height: 20em;
    }
    .user-profile-item__content {
        width: 25em;
        padding: 10px;
    }
}

.user-profile-item__image{
    margin: 1rem;
    width: 8rem;
    height: 8rem;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
}

.user-profile-item__content{
    background-color: rgb(99, 129, 119);
    color: rgb(223, 220, 217);
}

.backgroundd-image{
    background-image: url('../../assets/white_back_e.jpg');
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh ;
    background-size:cover;
}