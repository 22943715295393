.machine-list {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 50%;
    max-width: 50rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.machineContainer-background {
    background-image: url('../../assets/white_back_e.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    padding-top: 5px;
    min-height: 115rem;
    opacity: 0.96;
}
.machineContainer-background  h2 {
    margin: 0 0 0.5rem 0;
    font-weight: bold;
    color: rgba(3, 44, 8, 0.918);
}
.machineContainer {
    margin-left: 5em;
    margin-right: 5em;
  }