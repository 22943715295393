.form-controls {
    margin: 2rem 0;
}

.form-controls label,
.form-controls input,
.form-controls textarea {
    display: block;
    color: black;
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: large;
}

.form-controls select{
    background-color: transparent;
    color: white;
    border-bottom: 2px solid white;
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
    font-size: medium;
    margin-top: 0.5rem;
    padding-bottom: 0.25rem;
}

.form-controls option{
    color: black;
}

.form-controls input,
.form-controls textarea {
    width: 100%;
    font: inherit;
    border-bottom: 2px solid black;
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
    background: transparent;
    padding: 0.15rem 0.25rem;
}

.form-controls input:focus,
.form-controls textarea:focus {
    outline: none;
    background: transparent;
    border-color: #388e3c;
}

.form-control--invalid label{
    color: #af0000;
    font-weight: bolder;
}
.form-control--invalid p {
    color: #af0000;
}

.form-control--invalid input,
.form-control--invalid textarea {
    border-color: red;
    background: transparent;
    border-top: transparent;
    border-left: transparent;
    border-right: transparent;
}