.button {
    font: inherit;
    padding: 0.5rem 1.5rem;
    border: 1px solid #388e3c;
    border-radius: 4px;
    background: #388e3c;
    color: white;
    cursor: pointer;
    margin-right: 1rem;
    text-decoration: none;
    display: inline-block;
}

.button:focus {
    outline: none;
}

.button:hover,
.button:active {
    background: #6abf69;
    border-color: #6abf69;
}

.button--inverse {
    background: transparent;
    color: #00c853;
}

.button--inverse:hover,
.button--inverse:active {
    color: white;
    background: #00c853;
}

.button--danger {
    background: #830000;
    border-color: #830000;
}

.button--danger:hover,
.button--danger:active {
    background: #f34343;
    border-color: #f34343;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
    background: #ccc;
    color: #979797;
    border-color: #ccc;
    cursor: not-allowed;
}

.button--small {
    font-size: 0.8rem;
}

.button--big {
    font-size: 1.5rem;
}