/*.user-profile-item {*/
/*    margin: 1rem 0;*/
/*}*/

.supplier-profile-item__content {

    display: flex;
    color: black;
    margin-top: 0.5%;
    /*margin-bottom: 5%;*/
    /*margin-left: auto;*/
    /*margin-right: auto;*/
    padding: 1rem;
    width: 50%;
    max-width: 40rem;
    max-height: 5rem;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 8px rgba(0, 0, 0);
    border-radius: 6px;
    background-color: rgba(173, 156, 0, 0.1);
}
.agent-supplier-profile-item__content {

    display: flex;
    color: black;
    margin-top: 0.5%;
    /*margin-bottom: 5%;*/
    /*margin-left: auto;*/
    /*margin-right: auto;*/
    padding: 1rem;
    width: 50%;
    min-width: 40rem;
    max-height: 5rem;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 8px rgba(0, 0, 0);
    border-radius: 6px;
    background-color: rgba(173, 156, 0, 0.1);
}

.date-picker_form {

    display: flex;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    width: 60%;
    max-width: 40rem;
    max-height: 5rem;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 0 2px 8px rgba(0, 0, 0);
    border-radius: 6px;
    background-color: rgba(173, 156, 0, 0.1);
}

.supplier-profile-item__info {
    padding: 1rem;
    text-align: center;
}

.supplier-profile-item__image {
    width: 100%;
    height: 12.5rem;
    margin-right: 1.5rem;
}

.supplier-profile-item__image img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.supplier-profile-item__info h2,
.supplier-profile-item__info h3,
.supplier-profile-item__info p {
    margin: 0 0 0.3rem 0;
}

.supplier-profile-item__actions {
    padding: 0;
    text-align: center;
    border-top: 1px solid #ccc;
}

.supplier-profile-item__modal-content {
    padding: 0;
}

.supplier-profile-item__modal-actions {
    text-align: right;
}

.supplier-profile-item__actions button,
.supplier-profile-item__actions a {
    margin: 0.5rem;
}


@media (min-width: 768px) {
    .supplier-profile-item__image {
        height: 14em;
    }

    .supplier-profile-item__content {
        display: flex;
        color: black;
        margin-top: 1%;
        /*margin-bottom: 5%;*/
        margin-left: auto;
        margin-right: auto;
        padding: 1rem;
        width: 100%;
        max-width: 60rem;
        max-height: 8rem;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 2px 8px rgba(0, 0, 0);
        border-radius: 6px;
        background-color: rgba(173, 156, 0, 0.1),
    }
    .agent-supplier-profile-item__content {

        display: flex;
        color: black;
        margin-top: 0.5%;
        /*margin-bottom: 5%;*/
        margin-left: auto;
        margin-right: auto;
        padding: 1rem;
        width: 50%;
        min-width: 40rem;
        max-height: 5rem;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 2px 8px rgba(0, 0, 0);
        border-radius: 6px;
        background-color: rgba(173, 156, 0, 0.1);
    }
    .supplier-profile-item__content label{
        color: black;
    }
    .supplier-profile-item__content button {
        margin-left: 0.2rem;
        margin-right: 0;
    }
    .supplier-profile-item__content input,
    .supplier-profile-item__content select{
        border-bottom: 2px solid black;
    }
    .supplier-profile-item__content select{
        margin-top: 0;
        margin-bottom: 0.6rem;
    }
}