.machine-item {
    margin: 0.8rem;
}

.machine-item__content {
    padding: 5;
    background-color:rgba(105, 168, 33, 0.89);
}

.machine-item__info {
    padding: 1rem;
    text-align: center;
}

.machine-item__image {
    width: 100%;
    height: 12.5rem;
    margin-right: 1.5rem;
}

.machine-item__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.machine-item-trough {
    margin: 0.8rem;
    max-width: 24rem;

}
.machine-item_content_trough{
    padding: 10px;
    background-color:rgba(105, 168, 33, 0.89) 
}
.machine-item__image_trough {
    width: 100%;
    height: 12.5rem;
    margin-right: 1.5rem;
}
.machine-item__image_trough img{
    width: 100%;
    height: 100%;
    object-fit: cover;;
}

.machine-item__info h2,
.machine-item__info h3,
.machine-item__info p {
    margin: 0 0 0.5rem 0;
}

.machine-item__info  h2 {
    margin: 0 0 0.5rem 0;
    font-weight: bold;
    color: rgb(3, 22, 5);
}

.machine-item__info  h3 {
    margin: 0 0 0.5rem 0;
    font-weight: normal;
    color: rgba(5, 37, 73, 0.986);
}

.machine-item__info  p{
    margin: 0 0 0.5rem 0;
    font-weight: bold;
    color: whitesmoke;
}


.machine-item__actions {
    padding: 1rem;
    text-align: center;
    border-top: 1px solid #ccc;
}

.machine-item__modal-content {
    padding: 0;
}

.machine-item__modal-actions {
    text-align: right;
}

.machine-item__actions button,
.machine-item__actions a {
    margin: 0.5rem;
    background-color:rgb(3, 63, 11);
}



@media (min-width: 768px) {
    .machine-item__image {
        height: 20rem;
    }
}