.nav-links-drawer {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .nav-links-drawer li {
    margin: 1rem;
  }
  
  .nav-links-drawer a {
    border: 1px solid transparent;
    color: #292929;
    text-decoration: none;
    padding: 0.5rem;
  }
  
  .nav-links-drawer a:hover,
  .nav-links-drawer a:active,
  .nav-links-drawer a.active {
    color: #388e3c;
    border-bottom: 4px solid #fff76a;
  }
  
  .nav-links-drawer button {
    cursor: pointer;
    border: 1px solid #292929;
    color: #292929;
    background: transparent;
    padding: 0.5rem;
    font: inherit;
  }
  
  .nav-links-drawer button:focus {
    outline: none;
  }
  
  .nav-links-drawer button:hover,
  .nav-links-drawer button:active {
    background: #292929;
    color: white;
  }
  
  @media (min-width: 1000px) {
    .nav-links-drawer {
      flex-direction: row;
    }
  
    .nav-links-drawer li {
      margin: 0.2rem 0.1rem;
    }
  
    .nav-links-drawer a {
      color: white;
      text-decoration: none;
    }
  
    .nav-links-drawer button {
      color: white;
      background: transparent;
      border: none;
    }
    .nav-links-drawer a:hover,
    .nav-links-drawer a:active,
    .nav-links-drawer a.active {
      border-bottom: 4px solid #fff76a;
      color: white;
    }
  
    .nav-links-drawer button:hover,
    .nav-links-drawer button:active {
      background-color: #388e3c;
      border-bottom: 4px solid #fff76a;
      color: white;
      border-radius: 0;
    }
  }
  