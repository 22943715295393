.user-form {
    list-style: none;
    margin-top: 5%;
    margin-bottom: 5%;
    padding-left: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 4rem;
    width: 100%;
    min-width: 40em;
    min-height: 45em;
    box-shadow: 0 2px 8px rgba(0, 0, 0);
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
}


.user-form input,
.user-form textarea,
.user-form option {
    margin-bottom: 4em;
}

.user-form input,
.user-form textarea,
.user-form select,
.user-form option,
.user-form label{
    color: black;
}

.user-form input,
.user-form textarea,
.user-form option {
    margin-bottom: 4em;
}

.user-form select{
    margin-bottom: 1em;
    border-bottom: 1px solid black;

}