.machine-form {
    list-style: none;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 2rem;
    width: 100%;
    min-width: 42em;
    min-height: 35em;
    box-shadow: 0 2px 8px rgba(26, 29, 26, 0.76);
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.8);
}
.machine-form input,
.machine-form textarea,
.machine-form option,
.machine-form select {
    margin-bottom: 4em;
}
.machine-form select{
    margin-bottom: 2em;
    border-bottom: 2px solid black;
}
.trough-form {
    list-style: none;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 2rem;
    width: 100%;
    min-width: 42em;
    min-height: 28em;
    box-shadow: 0 2px 8px rgba(26, 29, 26, 0.76);
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.8);
}
.trough-form input,
.trough-form textarea,
.trough-form option {
    margin-bottom: 4em;
}
.trough-update-form {
    list-style: none;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 2rem;
    width: 100%;
    min-width: 40em;
    min-height: 20em;
    box-shadow: 0 2px 8px rgba(26, 29, 26, 0.76);
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.8);
}
.machine-update-form {
    list-style: none;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 2rem;
    width: 100%;
    min-width: 42em;
    min-height: 28em;
    box-shadow: 0 2px 8px rgba(26, 29, 26, 0.76);
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.8);
}