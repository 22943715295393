.variance-item {
    margin-top: 3rem;

    /*width: 55%;*/

}

.variance-item__content {
    background-image: linear-gradient(to right,#88df9a, #65c578, #279147);
    padding: 0;
    min-width:50em ;
}

.variance-item__info {
    padding: 6rem 0;
    text-align: start;
    
}



.variance-item__info h2,
.variance-item__info h3,
.variance-item__info p {
    margin: 0 0 0.5rem 0;
}
/*.variance-item__info h3{*/
/*    font-size: 1em;*/
/*}*/
 .variance-item:hover h2,
 .variance-item:active h2
{
    color: whitesmoke;
}
.variance-item:hover h3,
.variance-item:active h3
{
    color: whitesmoke;
}

.variance-item__actions {
    padding: 0.2rem;
    text-align: center;
    border-top: 5px solid #ccc;
    font-size:large;
}
.variance-item__actions_above {
    padding: 0.2rem;
    text-align: center;
    border-bottom: 5px solid #ccc;
    color:gainsboro;
}



.variance-item__modal-content {
    padding: 0;
}

.variance-item__modal-actions {
    text-align: right;
}

/*.variance-item__actions button,*/
/*.variance-item__actions a {*/
/*    margin: 0.5rem;*/
/*}*/



/*@media (min-width: 768px) {*/
/*    .machine-item__image {*/
/*        height: 20rem;*/
/*    }*/
/*}*/