.user-item {
    margin: 1rem 0;
    width: 100%;
    max-width: 20rem;
}

.user-item__info{
    font-family: 'Gill Sans MT';
}

.user-item a:hover,
.user-item a:active {
    opacity: 0.8;
}

.user-item__content {
    padding: 10px;
}

.user-item__image {
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
}
/* mk */
.user-item__info h1 {
    margin: 0 0 0.5rem 0;
    font-weight: normal;
    color: #ffd900;
}

.user-item__info h2 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem 0;
    font-weight: normal;
}

.user-item:hover h1,
.user-item:active h1,
.user-item:hover h2,
.user-item:active h2
 {
    color: #292929;
}

.user-item__info h3 {
    margin: 0;
}

.user-item__actions {
    padding: 1rem;
    text-align: center;
    border-top: 1px solid #ccc;
}


.user-item__modal-content {
    padding: 0;
}

.user-item__modal-actions {
    text-align: right;
}

.user-item__actions button,
.user-item__actions a {
    margin: 0.5rem;
    background-color:teal;
}

.user-item__content{
    background-color: 
    rgb(99, 129, 119)
}

.name {
    font-size: 1.8rem;
    margin: 0 0 0.5rem 0;
    font-weight: bolder;
    color: wheat;
}

.name:hover ,
.name:active 
{
    color: whitesmoke;
}

.type {
    margin: 0;
    font-weight: bold;
    color: whitesmoke;
}

.id{
    color: whitesmoke;
}

.telehone{
    color: whitesmoke;
}

.address{
    color: whitesmoke;
}

.nic{
    color: whitesmoke;
}

