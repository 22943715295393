/* .auth-form a:link {
    color: white;
    background-color: transparent;
    text-decoration: none;
  }

  .auth-form a:active {
    color: white;
    background-color: transparent;
    text-decoration: none;
  } */
  /* .auth-form{ */
    /* list-style: none;
    margin-top: 5%;
    margin-bottom: 5%;
    padding-left: 4rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 4rem;
    width: 100%;
    min-width: 40em;
    min-height: 45em;
    box-shadow: 0 2px 8px rgba(0, 0, 0);
    border-radius: 10px; */
    /* background: rgb(99, 129, 119)
  } */
.auth-form a:hover {
    color: green;
    background-color: transparent;
    text-decoration: underline;
    cursor:pointer;
  }

  /* .auth-form div {
    cursor:pointer;
  } */

  /* .auth-form b {
    color: white;
  } */
  